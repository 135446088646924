<template>
  <v-row class="mt-2">
    <v-col align="left">
      <cancel-order-dialog :order="order" />
    </v-col>
    <v-col align="right">
      <deliver-order-dialog :order="order" />
    </v-col>
  </v-row>
</template>

<script>
import CancelOrderDialog from "./CancelOrderDialog";
import DeliverOrderDialog from "./DeliverOrderDialog";

export default {
  components: {
    CancelOrderDialog,
    DeliverOrderDialog
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
  <v-card
    class="mx-auto"
    outlined
    :class="newOrders.length ? 'v-card-warning' : 'v-card-success'"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title v-if="newOrders.length" class="headline mb-1">
          {{ newOrders.length }} nya bokningar att bekräfta
        </v-list-item-title>
        <v-list-item-title v-else class="headline mb-1">
          Det finns inga nya bokningar att bekräfta
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon large>
          mdi-inbox-arrow-down
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn to="/inbox" outlined text large>
        TA MIG TILL INKORGEN
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InboxCard",
  computed: {
    ...mapGetters(["newOrders"])
  }
};
</script>

<template>
  <v-container
    class="py-10 d-flex flex-column justify-space-between"
    style="height: 100%"
    fluid
  >
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          src="@/assets/images/logo/800px/firren_logo_svart_rgb_800x250.png"
          contain
          style="width: 100%; max-width: 800px"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn x-large @click.prevent="signInWithGoogle">
          <strong>LOGGA IN MED GMAIL</strong>
          <v-icon dark large class="pl-2" color="#EA4335">
            mdi-gmail
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  methods: {
    signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider);
    }
  }
};
</script>

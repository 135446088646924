<template>
  <v-dialog v-model="cancelDialog" width="500" @click:outside="onCancel">
    <template v-slot:activator="{ on, attrs }">
      <v-btn large v-bind="attrs" v-on="on" outlined text>
        LÄGG TILL TRANSAKTION
        <v-icon class="ml-2">
          mdi-cash-100
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center blue darken-2">
        <h1 class="text-h4 white--text">
          Lägg till transaktion
        </h1>
      </div>

      <v-container>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td style="width: 1px;">
                  Presentkortsnummer:
                </td>
                <td>{{ giftcard.giftcardId }}</td>
              </tr>
              <tr>
                <td>
                  Inköpsdatum:
                </td>
                <td>{{ giftcard.createdAt | formatDate }}</td>
              </tr>
              <tr>
                <td>
                  Värde:
                </td>
                <td>{{ giftcard.value }}</td>
              </tr>
              <tr>
                <td>
                  Kvarvarande värde:
                </td>
                <td>{{ currentValue }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row class="mt-4">
          <v-col>
            <v-text-field label="Värde" type="number" v-model="amount" />
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="px-2 pb-2">
        <v-btn color="error" @click.prevent="onCancel" large outlined text>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.prevent="onConfirm" large outlined text>
          Lägg till
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { saveGiftcard } from "../../firebase/giftcards";

export default {
  props: {
    giftcardId: {
      default: () => undefined,
      type: String
    }
  },
  data() {
    return {
      cancelDialog: false,
      amount: 0
    };
  },
  computed: {
    ...mapGetters(["giftcards"]),
    giftcard() {
      return this.giftcards.find(
        giftcard => giftcard.giftcardId === this.giftcardId
      );
    },
    currentValue() {
      if (!this.giftcard) {
        return Number.NaN;
      }

      return (
        this.giftcard.value -
        this.giftcard.transactions.reduce((acc, { amount }) => acc + amount, 0)
      );
    }
  },
  methods: {
    isValid() {
      if (this.amount <= 0) {
        return false;
      }

      if (this.amount > this.currentValue) {
        return false;
      }

      return true;
    },
    async onConfirm() {
      if (!this.isValid()) {
        console.log("Unable to save transaction", {
          giftcard: this.giftcard,
          amount: this.amount
        });
        return;
      }

      await saveGiftcard({
        ...this.giftcard,
        transactions: [
          ...this.giftcard.transactions,
          {
            amount: this.amount,
            createdAt: new Date()
          }
        ]
      });

      this.cancelDialog = false;
    },
    onCancel() {
      this.cancelDialog = false;
    }
  }
};
</script>

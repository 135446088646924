<template>
  <v-card class="mx-auto" max-height="400" style="padding: 16px" outlined>
    <v-card-title>
      Bokningsstatistik
    </v-card-title>
    <bar-chart :styles="myStyles" :orders="orders" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import BarChart from "../charts/BarChart";

export default {
  name: "OrderStatisticsCard",
  components: {
    BarChart
  },
  computed: {
    ...mapGetters(["orders"])
  },
  data() {
    return {
      myStyles: {
        height: "300px",
        width: "100%",
        position: "relative"
      }
    };
  }
};
</script>

<template>
  <v-dialog v-model="cancelDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        style="height: 100%; border-top: unset; border-right: unset; border-bottom: unset; border-radius: 0px;"
        large
        outlined
        text
        v-bind="attrs"
        v-on="on"
        :disabled="!orders || !orders.length"
        >Anonymisera

        <v-icon right dark>
          mdi-cancel
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center red darken-2">
        <h1 class="text-h4 white--text">Anonymisera Bokningar</h1>
      </div>

      <v-container>
        <v-row>
          <v-col class="text-center my-4">
            helt säker på att du vill <strong>ANONYMISERA</strong>
            {{ orders.length }} bokning(ar). Det här går
            <strong>INTE</strong> att ångra.
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="error" text @click.prevent="onCancel" outlined>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.prevent="onConfirm" outlined>
          Anonymisera
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      cancelDialog: false
    };
  },
  methods: {
    async onConfirm() {
      this.$emit("confirm");
      this.cancelDialog = false;
    },
    onCancel() {
      this.$emit("cancel");
      this.cancelDialog = false;
    }
  }
};
</script>

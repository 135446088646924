<template>
  <v-container class="py-8 px-6" fluid>
    <v-alert :color="alert" v-if="alert">
      <template v-if="alert === 'success'">
        <h2>Klart!</h2>
        <span>Bokningarna har blivit anonymiserade</span>
      </template>
      <template v-if="alert === 'error'">
        <h2>Hoppsan!</h2>
        <span>Något gick fel. Prova igen.</span>
      </template>
    </v-alert>
    <v-form @submit.prevent="search">
      <v-row>
        <v-col>
          <v-text-field
            hide-details
            single-line
            v-model="emailToSearch"
            label="Epost"
            type="email"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn
            large
            outlined
            text
            data-testid="openConfirmOrderDialogButton"
            type="submit"
          >
            Sök

            <v-icon right dark>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined>
          <v-subheader class="d-flex justify-space-between pr-0 mr-0"
            >Bokningar för: {{ searchedEmail }}
            <forget-me-confirmation-dialog
              :orders="orders"
              @confirm="anonymize"
            />
          </v-subheader>
        </v-card>
      </v-col>
      <v-col v-for="[key, os] in orderGroups" :key="key" cols="12">
        <v-card flat outlined>
          <v-subheader>{{ key }} ({{ os.length }})</v-subheader>

          <v-expansion-panels flat>
            <order
              v-for="order in os"
              :key="order.orderId"
              :order="order"
              no-toolbar
            />
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ForgetMeConfirmationDialog from "../components/gdpr/ForgetMeConfirmationDialog";
import Order from "../components/Order";

import { anonymizeOrder, getOrdersByEmail } from "../firebase/orders";
import { groupBy } from "lodash";

export default {
  data: function() {
    return {
      searchedEmail: "",
      emailToSearch: "",
      orders: [],
      alert: "",
      alertTimer: null
    };
  },
  components: {
    Order,
    ForgetMeConfirmationDialog
  },
  computed: {
    orderGroups() {
      return Object.entries(groupBy(this.orders, order => order.status));
    }
  },
  methods: {
    async search() {
      this.orders.length = 0;

      const orders = await getOrdersByEmail(this.emailToSearch);

      this.searchedEmail = this.emailToSearch;
      this.emailToSearch = "";

      orders.forEach(order => {
        this.orders.push(order);
      });
    },
    clearAlert() {
      this.alert = "";
      this.alertTimer = null;
    },
    async anonymize() {
      try {
        await Promise.resolve(
          this.orders.map(({ orderId }) => anonymizeOrder(orderId))
        );
        this.alert = "success";
      } catch (e) {
        console.error(e);
        this.alert = "error";
      }

      this.orders.length = 0;
      this.orderGroups.length = 0;
      this.emailToSearch = "";
      this.searchedEmail = "";

      if (this.alertTimer) {
        clearTimeout(this.alertTimer);
      }

      this.alertTimer = setTimeout(this.clearAlert, 10 * 1000);
      this.$forceUpdate();
    }
  }
};
</script>

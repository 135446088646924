<template>
  <v-container class="py-8 px-6" fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined>
          <v-subheader>Ordrar som väntar på kund</v-subheader>

          <order-panels :orders="changeRequestedOrders" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import OrderPanels from "../components/OrderPanels.vue";

export default {
  components: {
    OrderPanels
  },
  computed: {
    ...mapGetters(["changeRequestedOrders"])
  }
};
</script>

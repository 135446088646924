<template>
  <v-card>
    <v-subheader>Nytt Presentkort</v-subheader>

    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            label="Presentkortsnummer"
            type="number"
            v-model="giftcard.giftcardId"
          />
        </v-col>
        <v-col>
          <v-text-field label="Värde" type="number" v-model="giftcard.value" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-item-group style="width: 100%" class="d-flex">
            <v-btn
              large
              outlined
              text
              class="flex-grow-1 mr-1"
              @click.prevent="giftcard.value = 500"
            >
              500 kr
            </v-btn>
            <v-btn
              large
              outlined
              text
              class="flex-grow-1 mx-1"
              @click.prevent="giftcard.value = 1000"
            >
              1000 kr
            </v-btn>
            <v-btn
              large
              outlined
              text
              class="flex-grow-1 mx-1"
              @click.prevent="giftcard.value = 1500"
            >
              1500 kr
            </v-btn>
            <v-btn
              large
              outlined
              text
              class="flex-grow-1 ml-1"
              @click.prevent="giftcard.value = 2000"
            >
              2000 kr
            </v-btn>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions class="px-3 pb-3 d-flex justify-space-between">
      <v-btn color="warning" @click="onCancelClicked" large outlined text>
        Avbryt
      </v-btn>
      <v-btn color="success" @click="onSaveClicked" large outlined text>
        Spara
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { saveGiftcard } from "../../firebase/giftcards";

export default {
  components: {},
  props: {},
  data() {
    return {
      giftcard: {
        giftcardId: "",
        value: 0,
        transactions: []
      }
    };
  },
  computed: {
    ...mapGetters(["giftcards"])
  },
  methods: {
    reset() {
      this.giftcard.giftcardId = "";
      this.giftcard.value = "";
    },
    isValid() {
      if (!this.giftcard.giftcardId) {
        return false;
      }

      if (
        this.giftcards.some(
          giftcard => this.giftcard.giftcardId === giftcard.giftcardId
        )
      ) {
        return false;
      }

      if (this.giftcard.value <= 0) {
        return false;
      }

      if (!this.giftcard.transactions?.length === 0) {
        return false;
      }

      return true;
    },
    async save() {
      if (!this.isValid()) {
        throw new Error("Could not save giftcard", { giftcard: this.giftcard });
      }

      await saveGiftcard(this.giftcard);

      return this.giftcard.giftcardId;
    },
    onCancelClicked() {
      this.reset();
      this.$emit("cancel");
    },
    async onSaveClicked() {
      const giftcardId = await this.save();
      this.$emit("saved", { giftcardId });
    }
  }
};
</script>

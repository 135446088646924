<template>
  <v-app id="inspire">
    <v-app-bar v-if="authenticated" app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Firren</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="authenticated" app>
      <v-container>
        <v-img src="@/assets/firren-logo.png" max-width="64" />
      </v-container>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="{ icon, text, countKey, path } in links"
          :key="path"
          link
          :to="path"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="countKey">
            <span>{{ getLength(countKey) }}</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>

        <v-list>
          <v-list-item link @click.prevent="onLogoutClicked">
            <v-list-item-icon>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { signOut } from "./firebase/authentication";

export default {
  computed: {
    ...mapGetters([
      "authenticated",
      "confirmedOrders",
      "newOrders",
      "changeRequestedOrders"
    ])
  },
  methods: {
    async onLogoutClicked() {
      await signOut();

      this.$router.push({
        name: "login"
      });
    },
    getLength(key) {
      return this[key].length;
    }
  },
  data: () => ({
    drawer: null,
    links: [
      {
        icon: "mdi-home",
        text: "Hem",
        path: "/"
      },
      {
        icon: "mdi-inbox-arrow-down",
        text: "Inkorg",
        countKey: "newOrders",
        path: "/inbox"
      },
      {
        icon: "mdi-clipboard-list-outline",
        text: "Bokningar",
        countKey: "confirmedOrders",
        path: "/orders"
      },
      {
        icon: "mdi-timer-sand-empty",
        text: "Väntar på kund",
        countKey: "changeRequestedOrders",
        path: "/awaiting-customer"
      },
      {
        icon: "mdi-check",
        text: "Levererade Bokningar",
        path: "/delivered"
      },
      {
        icon: "mdi-cancel",
        text: "Makulerade Bokningar",
        path: "/cancelled"
      },
      {
        icon: "mdi-card",
        text: "Presentkort",
        path: "/giftcards"
      },
      {
        icon: "mdi-account-multiple",
        text: "GDPR",
        path: "/gdpr"
      }
    ]
  })
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/main.sass";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-sheet.v-card.v-card-warning {
  border-right: 8px solid map-get($amber, "base") !important;
}

.v-sheet.v-card.v-card-success {
  border-right: 8px solid map-get($green, "base") !important;
}
</style>

<template>
  <v-dialog v-model="cancelDialog" width="500" @click:outside="onCancel">
    <template v-slot:activator="{ on, attrs }">
      <v-btn large v-bind="attrs" v-on="on" outlined text>
        SKRIV UT BOKNINGAR
        <v-icon v-if="icon" class="ml-2">
          mdi-printer
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center blue darken-2">
        <h1 class="text-h4 white--text">
          Skriv ut Bokningar
        </h1>
      </div>

      <v-date-picker
        full-width
        v-model="dates"
        range
        locale="sv-se"
        first-day-of-week="1"
        flat
        no-title
      />

      <v-card-title v-if="dates.length === 2" class="d-block text-center">
        Det finns {{ ordersInRange.length }} bokningar i det här
        datumintervallet
      </v-card-title>
      <v-card-title v-else class="d-block text-center">
        Välj ett datumintervall
      </v-card-title>

      <div class="mx-4 my-2">
        <v-checkbox label="Inkludera försättsblad" v-model="includeIndex" />
      </div>

      <v-divider />

      <v-card-actions>
        <v-btn color="error" text @click.prevent="onCancel" large>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click.prevent="onConfirm"
          :disabled="dates.length !== 2"
          large
        >
          Skriv ut
          <v-icon class="ml-2">
            mdi-printer
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isAfter, isBefore } from "date-fns";
import { mapGetters } from "vuex";

export default {
  props: {
    icon: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      cancelDialog: false,
      dates: [],
      includeIndex: false
    };
  },
  computed: {
    ...mapGetters(["confirmedOrders"]),
    from() {
      if (this.dates.length !== 2) {
        return undefined;
      }

      return isBefore(new Date(this.dates[0]), new Date(this.dates[1]))
        ? this.dates[0]
        : this.dates[1];
    },
    to() {
      if (this.dates.length !== 2) {
        return undefined;
      }

      return isBefore(new Date(this.dates[0]), new Date(this.dates[1]))
        ? this.dates[1]
        : this.dates[0];
    },
    ordersInRange() {
      return this.confirmedOrders.filter(
        order =>
          isAfter(new Date(order.deliveryDate), new Date(this.from)) &&
          isBefore(new Date(order.deliveryDate), new Date(this.to))
      );
    }
  },
  methods: {
    async onConfirm() {
      this.cancelDialog = false;

      this.$router.push({
        name: "print",
        query: {
          from: this.from,
          to: this.to,
          includeIndex: this.includeIndex
        }
      });

      this.dates.length = 0;
    },
    onCancel() {
      this.cancelDialog = false;
      this.dates.length = 0;
    }
  }
};
</script>

<template>
  <v-dialog v-model="cancelDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="error" large v-bind="attrs" v-on="on"
        >Makulera

        <v-icon right dark>
          mdi-cancel
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center red darken-2">
        <h1 class="text-h4 white--text">Makulera Order {{ order.orderId }}</h1>
      </div>

      <v-container>
        <v-row>
          <v-col class="text-center my-4">
            helt säker på att du vill <strong>MAKULERA</strong> order
            {{ order.orderId }}
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="error" text @click.prevent="onCancel" outlined>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.prevent="onConfirm" outlined>
          Makulera
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelOrder } from "../../firebase/orders";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cancelDialog: false
    };
  },
  methods: {
    async onConfirm() {
      await cancelOrder(this.order.orderId);
      this.cancelDialog = false;
    },
    onCancel() {
      this.cancelDialog = false;
    }
  }
};
</script>

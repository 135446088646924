<template>
  <v-card
    class="mx-auto"
    max-height="200px"
    outlined
    :class="todayOrders.length ? 'v-card-warning' : 'v-card-success'"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title v-if="todayOrders.length" class="headline mb-1">
          {{ todayOrders.length }} bokningar kvar som ska hämtas idag
        </v-list-item-title>
        <v-list-item-title v-else class="headline mb-1">
          Det finns inga bokningar kvar som ska hämtas idag
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon large>
          mdi-clipboard-list-outline
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>
    <v-card-actions>
      <v-btn to="/orders" outlined text large>
        TA MIG TILL ALLA BOKNINGAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { isToday } from "date-fns";

export default {
  name: "OrdersCard",
  computed: {
    ...mapGetters(["confirmedOrders"]),
    todayOrders() {
      return this.confirmedOrders.filter(order =>
        isToday(new Date(order.deliveryDate))
      );
    }
  }
};
</script>

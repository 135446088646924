<template>
  <v-container class="py-8 px-6" fluid>
    <v-row class="mb-2">
      <v-col>
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          single-line
          v-model="filter"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card flat outlined>
          <v-subheader>Levererade Bokningar</v-subheader>

          <order-panels :orders="orders" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { orderBy } from "lodash";

import OrderPanels from "../components/OrderPanels";

export default {
  components: {
    OrderPanels
  },
  data() {
    return {
      filter: ""
    };
  },
  computed: {
    ...mapGetters(["deliveredOrders"]),
    orders() {
      return orderBy(
        this.deliveredOrders.filter(this.matchesFilter),
        order => new Date(order.deliveredAt)
      );
    }
  },
  methods: {
    matchesFilter(order) {
      if (!order) {
        return false;
      }

      const { orderId, customer } = order;

      if (orderId?.startsWith(this.filter)) {
        return true;
      }

      if (customer?.name?.toLowerCase().includes(this.filter.toLowerCase())) {
        return true;
      }

      if (customer?.email?.toLowerCase().includes(this.filter.toLowerCase())) {
        return true;
      }

      return false;
    }
  }
};
</script>

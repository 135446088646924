import {
  ORDER_NEW,
  ORDER_CANCELLED,
  ORDER_DELIVERED,
  ORDER_CONFIRMED,
  ORDER_CHANGE_REQUESTED
} from "../constants";

import store from "../store";
import firebase from "./firebase";

const db = firebase.firestore();
const ordersCollection = db.collection("orders");

let ordersListenerCleanup = null;

export const setupListeners = () => {
  if (ordersListenerCleanup) {
    cleanupListeners();
  }

  ordersListenerCleanup = ordersCollection
    .where("orderId", "!=", "_meta")
    .onSnapshot(snapshot => {
      console.log("orders updated");

      const orders = [];

      snapshot.forEach(doc => orders.push(doc.data()));

      console.log(orders);
      store.commit({
        type: "setOrders",
        orders
      });
    });
};

export const cleanupListeners = () => {
  if (!ordersListenerCleanup) {
    return;
  }

  ordersListenerCleanup();

  ordersListenerCleanup = null;
};

export const confirmOrderChangeRequest = async orderId => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  const orderData = order.data();

  if (orderData.status !== ORDER_CHANGE_REQUESTED) {
    throw new Error(
      "Unable to confirm change request on order in wrong status"
    );
  }

  const confirmedOrder = {
    ...orderData,
    status: ORDER_CONFIRMED,
    items: orderData.proposedItems
  };

  delete confirmedOrder.proposedItems;

  return orderReference.set(confirmedOrder);
};

export const confirmOrder = async (orderId, proposedItems) => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  if (proposedItems?.length) {
    return orderReference.set({
      ...order.data(),
      status: ORDER_CHANGE_REQUESTED,
      confirmedAt: new Date(),
      proposedItems
    });
  }

  return orderReference.set({
    ...order.data(),
    status: ORDER_CONFIRMED,
    confirmedAt: new Date()
  });
};

export const cancelOrder = async orderId => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  await orderReference.set({
    ...order.data(),
    status: ORDER_CANCELLED,
    cancelledAt: new Date()
  });
};

export const deliverOrder = async orderId => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  await orderReference.set({
    ...order.data(),
    status: ORDER_DELIVERED,
    deliveredAt: new Date()
  });
};

export const resetOrder = async orderId => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  const orderData = order.data();

  delete orderData.confirmedAt;
  delete orderData.deliveredAt;
  delete orderData.cancelledAt;

  await orderReference.set({
    ...orderData,
    status: ORDER_NEW
  });
};

export const saveOrderComment = async (orderId, comment) => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  await orderReference.set({
    ...order.data(),
    comment
  });
};

export const getOrdersByEmail = async email => {
  const snapshot = await ordersCollection
    .where("customer.email", "==", email)
    .get();

  if (snapshot.empty) {
    return [];
  }

  const orders = [];

  snapshot.forEach(doc => {
    orders.push(doc.data());
  });

  return orders;
};

export const anonymizeOrder = async orderId => {
  const orderReference = ordersCollection.doc(`${orderId}`);
  const order = await orderReference.get();

  if (!order.exists) {
    throw new Error(`Unable to locate order with orderId: ${orderId}`);
  }

  await orderReference.set({
    ...order.data(),
    customer: {
      name: "Anonymous",
      email: "anonymous@firren.org",
      phone: "0709999999"
    }
  });
};

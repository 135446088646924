<template>
  <v-card class="mx-auto" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          Skriv ut Bokningar
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon large>
          mdi-printer
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <print-dialog />
    </v-card-actions>
  </v-card>
</template>

<script>
import PrintDialog from "./PrintDialog.vue";

export default {
  name: "PrintCard",
  components: {
    PrintDialog
  },
  methods: {
    onPrintClicked() {
      console.log("Print clicked");
    }
  }
};
</script>

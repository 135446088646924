import Vue from "vue";
import Vuex from "vuex";
import {
  ORDER_CANCELLED,
  ORDER_CHANGE_REQUESTED,
  ORDER_CONFIRMED,
  ORDER_DELIVERED,
  ORDER_NEW,
  ORDER_DENIED
} from "../constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    orders: [],
    giftcards: [],
    currentUser: null
  },
  mutations: {
    setOrders(state, action) {
      state.orders = action.orders.map(order => ({
        ...order,
        orderedAt: order.orderedAt && order.orderedAt.toDate(),
        deliveredAt: order.deliveredAt && order.deliveredAt.toDate(),
        confirmedAt: order.confirmedAt && order.confirmedAt.toDate(),
        cancelledAt: order.cancelledAt && order.cancelledAt.toDate()
      }));
    },
    setGiftcards(state, action) {
      state.giftcards = action.giftcards.map(giftcard => ({
        ...giftcard,
        createdAt: giftcard.createdAt.toDate(),
        transactions:
          giftcard.transactions &&
          giftcard.transactions.map(transaction => ({
            ...transaction,
            createdAt: transaction.createdAt && transaction.createdAt.toDate()
          }))
      }));
    },
    setCurrentUser(state, action) {
      state.currentUser = action;
    },
    signedOut(state) {
      state.orders = [];
      state.giftcards = [];
      state.currentUser = null;
    }
  },
  getters: {
    orders: state => state.orders,
    newOrders: state => state.orders?.filter(o => o.status === ORDER_NEW),
    confirmedOrders: state =>
      state.orders?.filter(o => o.status === ORDER_CONFIRMED),
    cancelledOrders: state =>
      state.orders?.filter(o => o.status === ORDER_CANCELLED),
    changeRequestedOrders: state =>
      state.orders?.filter(o => o.status === ORDER_CHANGE_REQUESTED),
    deliveredOrders: state =>
      state.orders?.filter(o => o.status === ORDER_DELIVERED),
    deniedOrders: state => state.orders?.filter(o => o.status === ORDER_DENIED),
    giftcards: state => state.giftcards,
    user: state => state.currentUser,
    authenticated: state => !!state.currentUser
  },
  actions: {},
  modules: {}
});

<template>
  <v-expansion-panels flat>
    <order v-for="order in orders" :key="order.orderId" :order="order" />
  </v-expansion-panels>
</template>

<script>
import Order from "./Order.vue";

export default {
  components: {
    Order
  },
  props: {
    orders: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/main.sass";

.v-expansion-panel {
  border-top: thin solid rgba(map-get($shades, "black"), 0.12);
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

table {
  border-collapse: collapse;
}

td {
  position: relative;
  padding: 5px 10px;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: thin solid rgba(map-get($shades, "black"), 0.4);
  width: 100%;
}
</style>

<template>
  <v-card outlined v-if="giftcard">
    <v-container>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="mb-1" style="height: unset; font-size: 1rem;">
                <strong>Presentkort</strong>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 1px;">
                Presentkortsnummer:
              </td>
              <td>{{ giftcard.giftcardId }}</td>
            </tr>
            <tr>
              <td>
                Inköpsdatum:
              </td>
              <td>{{ giftcard.createdAt | formatDate }}</td>
            </tr>
            <tr>
              <td>
                Värde:
              </td>
              <td>{{ giftcard.value }}</td>
            </tr>
            <tr>
              <td>
                Kvarvarande värde:
              </td>
              <td>{{ currentValue }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table class="my-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="mb-1 border-bottom"
                style="height: unset; font-size: 1rem;"
              >
                <strong>Transaktioner</strong>
              </th>
              <th class="border-bottom" />
            </tr>
            <tr>
              <th>Tidpunkt</th>
              <th>Värde</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in giftcard.transactions"
              :key="transaction.createdAt | formatISO"
            >
              <td>{{ transaction.createdAt | formatTime }}</td>
              <td>{{ transaction.amount }}</td>
            </tr>
            <tr v-if="giftcard.transactions.length === 0">
              <td colspan="2" align="center" class="text-caption">
                Inga transaktioner
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-card-actions>
      <add-transaction-dialog :giftcardId="giftcard.giftcardId" />
    </v-card-actions>
  </v-card>
</template>

<script>
import AddTransactionDialog from "./AddTransactionDialog.vue";

export default {
  components: { AddTransactionDialog },
  props: {
    giftcard: {
      default: () => undefined,
      type: Object
    }
  },
  computed: {
    currentValue() {
      if (!this.giftcard) {
        return Number.NaN;
      }

      return (
        this.giftcard.value -
        this.giftcard.transactions.reduce((acc, { amount }) => acc + amount, 0)
      );
    }
  }
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/main.sass";

.border-bottom {
  border-bottom: thin solid rgba(map-get($shades, "black"), 0.12);
}
</style>

import Vue from "vue";
import { format, formatISO } from "date-fns";

Vue.filter("formatDate", value => {
  if (!value) {
    return "";
  }

  return formatISO(new Date(value), { representation: "date" });
});

Vue.filter("formatTime", value => {
  if (!value) {
    return "";
  }

  return format(new Date(value), "yyyy-MM-dd HH:mm");
});

Vue.filter("formatISO", value => {
  if (!value) {
    return "";
  }

  return formatISO(new Date(value));
});

<template>
  <v-card
    class="mx-auto"
    max-height="200px"
    outlined
    :class="changeRequestedOrders.length ? 'v-card-warning' : 'v-card-success'"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title
          v-if="changeRequestedOrders.length"
          class="headline mb-1"
        >
          {{ changeRequestedOrders.length }} bokningar som väntar på kund
        </v-list-item-title>
        <v-list-item-title v-else class="headline mb-1">
          Det finns inga bokningar som väntar på kund
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon large>
          mdi-timer-sand-empty
        </v-icon>
      </v-list-item-avatar>
    </v-list-item>
    <v-card-actions>
      <v-btn to="/awaiting-customer" outlined text large>
        TA MIG TILL BOKNINGAR SOM VÄNTAR PÅ KUND
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrdersCard",
  computed: {
    ...mapGetters(["changeRequestedOrders"])
  }
};
</script>

import { Bar } from "vue-chartjs";
import { subDays, formatISO, addDays, isSameDay } from "date-fns";

export default {
  extends: Bar,
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  methods: {
    getNewOrdersForDay(day) {
      return this.orders.filter(o => isSameDay(o.orderedAt, day)).length;
    },
    getConfirmedOrdersForDay(day) {
      return this.orders.filter(o => isSameDay(o.confirmedAt, day)).length;
    },
    getCancelledOrdersForDay(day) {
      return this.orders.filter(o => isSameDay(o.cancelledAt, day)).length;
    },
    getDeliveredOrdersForDay(day) {
      return this.orders.filter(o => isSameDay(o.deliveredAt, day)).length;
    },
    render() {
      this.renderChart(
        {
          labels: Array.from({ length: 10 }).map((_, i) =>
            formatISO(addDays(this.startDate, i), { representation: "date" })
          ),
          datasets: [
            {
              label: "Inkomna Bokningar",
              backgroundColor: "blue",
              data: this.newOrders,
              barPercentage: 0.8
            },
            {
              label: "Bekräftade Bokningar",
              backgroundColor: "#4caf50",
              data: this.confirmedOrders,
              barPercentage: 0.8
            },
            {
              label: "Makulerade Bokningar",
              backgroundColor: "#ff5252",
              data: this.cancelledOrders,
              barPercentage: 0.8
            },
            {
              label: "Levererade Bokningar",
              backgroundColor: "#ffc107",
              data: this.deliveredOrders,
              barPercentage: 0.8
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          }
        }
      );
    }
  },
  computed: {
    days() {
      return 10;
    },
    startDate() {
      return subDays(new Date(), this.days - 1);
    },
    newOrders() {
      return Array.from({ length: this.days }).map((_, i) =>
        this.getNewOrdersForDay(addDays(this.startDate, i))
      );
    },
    cancelledOrders() {
      return Array.from({ length: this.days }).map((_, i) =>
        this.getCancelledOrdersForDay(addDays(this.startDate, i))
      );
    },
    confirmedOrders() {
      return Array.from({ length: this.days }).map((_, i) =>
        this.getConfirmedOrdersForDay(addDays(this.startDate, i))
      );
    },
    deliveredOrders() {
      return Array.from({ length: this.days }).map((_, i) =>
        this.getDeliveredOrdersForDay(addDays(this.startDate, i))
      );
    }
  },
  mounted() {
    this.render();
  },
  watch: {
    orders: {
      deep: true,
      handler() {
        this.render();
      }
    }
  }
};

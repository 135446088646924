<template>
  <v-container class="py-8 px-6" fluid>
    <v-row>
      <v-col sm="12" md="6">
        <inbox-card />
      </v-col>

      <v-col sm="12" md="6">
        <orders-card />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" md="6">
        <awaiting-customer-card />
      </v-col>

      <v-col sm="12" md="6">
        <print-card />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <order-statistics-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AwaitingCustomerCard from "../components/homeCards/AwaitingCustomerCard.vue";
import InboxCard from "../components/homeCards/InboxCard.vue";
import OrderStatisticsCard from "../components/homeCards/OrderStatisticsCard.vue";
import OrdersCard from "../components/homeCards/OrdersCard.vue";
import PrintCard from "../components/homeCards/PrintCard.vue";

export default {
  components: {
    AwaitingCustomerCard,
    InboxCard,
    OrderStatisticsCard,
    OrdersCard,
    PrintCard
  }
};
</script>

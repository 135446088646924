<template>
  <v-row class="mt-2"> </v-row>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon large v-bind="attrs" v-on="on">
        <v-icon dark>
          mdi-pencil-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center green darken-2">
        <h1 class="text-h5 white--text">
          Kommentarer för order: {{ order.orderId }}
        </h1>
      </div>

      <v-container>
        <v-textarea v-model="comment" auto-grow rows="2" />
      </v-container>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="error" text @click.prevent="onCancel" outlined>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click.prevent="onConfirm" outlined>
          Spara
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { saveOrderComment } from "../../firebase/orders";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comment: "",
      dialog: false
    };
  },
  mounted() {
    if (this.order.comment) {
      this.comment = this.order.comment;
    }
  },
  methods: {
    async onConfirm() {
      await saveOrderComment(this.order.orderId, this.comment);
      this.dialog = false;
    },
    onCancel() {
      this.comment = this.order.comment || "";
      this.dialog = false;
    }
  }
};
</script>

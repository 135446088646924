<template>
  <v-container
    class="py-10 d-flex flex-column justify-space-between"
    style="height: 100%"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h1>Ej Behörig</h1>
        <p>Du försökte logga in med en obehörig användare.</p>
        <router-link to="/login">
          Ta mig tillbaka
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  methods: {
    signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider);
    }
  }
};
</script>

<template>
  <v-row class="mt-2">
    <v-col align="left">
      <cancel-order-dialog :order="order" />
    </v-col>
    <v-col align="right">
      <confirm-order-dialog :order="order" />
    </v-col>
  </v-row>
</template>

<script>
import CancelOrderDialog from "./CancelOrderDialog";
import ConfirmOrderDialog from "./ConfirmOrderDialog.vue";

import { confirmOrder, cancelOrder } from "../../firebase/orders";

export default {
  components: {
    CancelOrderDialog,
    ConfirmOrderDialog
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmDialog: false,
      cancelDialog: false
    };
  },
  methods: {
    onPrintClicked(orderId) {
      this.$htmlToPaper(`order-${orderId}`);
    },
    async onCancel() {
      await cancelOrder(this.order.orderId);
      this.cancelDialog = false;
    },
    onCancelCancel() {
      this.cancelDialog = false;
    },
    async onConfirm() {
      await confirmOrder(this.order.orderId);
      this.confirmDialog = false;
    },
    onConfirmCancel() {
      this.confirmDialog = false;
    }
  }
};
</script>

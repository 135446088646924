<template>
  <v-expansion-panel :id="`order-${order.id}`">
    <v-expansion-panel-header>
      <v-row no-gutters>
        <v-col cols="auto" class="mr-4">
          <order-note-dialog :order="order" />
        </v-col>

        <v-col class="d-flex align-center">
          <span class="text-subtitle-1">Bokning {{ order.orderId }}</span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-row>
        <v-col>
          <v-form style="padding-top: 16.4px">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.customer.name"
                  label="Namn"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.customer.email"
                  label="Epost"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.customer.phone"
                  label="Telefonnummer"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="orderedAt"
                  label="Bokningstid"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.deliveryDate"
                  label="Leveransdatum"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="order.deliveredAt">
              <v-col>
                <v-text-field
                  v-model="deliveredAt"
                  label="Levererad"
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="1" />
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Vara
                  </th>
                  <th class="text-left">
                    Antal
                  </th>
                  <th class="text-left">
                    Enhet
                  </th>
                  <th class="text-left">
                    pris
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in order.items"
                  :key="item.id"
                  :class="shouldStrike(item) ? 'strikeout' : ''"
                >
                  <td>{{ item.title }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.unit }}</td>
                  <td>{{ item.price }} kr/{{ item.unit }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row v-if="order.comment">
        <v-col>
          <v-textarea
            v-model="order.comment"
            label="Kommentar"
            auto-grow
            dense
            readonly
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider />

      <template v-if="noToolbar === false">
        <new-order-toolbar v-if="order.status === ORDER_NEW" :order="order" />
        <confirmed-order-toolbar
          v-else-if="order.status === ORDER_CONFIRMED"
          :order="order"
        />
        <cancelled-order-toolbar
          v-else-if="order.status === ORDER_CANCELLED"
          :order="order"
        />
        <delivered-order-toolbar
          v-else-if="order.status === ORDER_DELIVERED"
          :order="order"
        />
        <awaiting-customer-toolbar
          v-else-if="order.status === ORDER_CHANGE_REQUESTED"
          :order="order"
        />
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatISO9075 } from "date-fns";

import NewOrderToolbar from "./orderToolbars/NewOrderToolbar";
import AwaitingCustomerToolbar from "./orderToolbars/AwaitingCustomerToolbar";
import ConfirmedOrderToolbar from "./orderToolbars/ConfirmedOrderToolbar";
import DeliveredOrderToolbar from "./orderToolbars/DeliveredOrderToolbar";
import CancelledOrderToolbar from "./orderToolbars/CancelledOrderToolbar";
import OrderNoteDialog from "./orderToolbars/OrderNoteDialog";

import {
  ORDER_NEW,
  ORDER_CANCELLED,
  ORDER_CONFIRMED,
  ORDER_DELIVERED,
  ORDER_CHANGE_REQUESTED
} from "../constants";

export default {
  components: {
    AwaitingCustomerToolbar,
    CancelledOrderToolbar,
    ConfirmedOrderToolbar,
    DeliveredOrderToolbar,
    NewOrderToolbar,
    OrderNoteDialog
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    noToolbar: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      ORDER_NEW,
      ORDER_CANCELLED,
      ORDER_DELIVERED,
      ORDER_CONFIRMED,
      ORDER_CHANGE_REQUESTED
    };
  },
  computed: {
    orderedAt() {
      return formatISO9075(this.order.orderedAt);
    },
    deliveredAt() {
      return formatISO9075(this.order.deliveredAt);
    }
  },
  methods: {
    shouldStrike(item) {
      if (!this.order.proposedItems) {
        return false;
      }

      return (
        this.order.proposedItems.some(
          proposedItem => item.id === proposedItem.id
        ) === false
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/main.sass";

.v-expansion-panel {
  border-top: thin solid rgba(map-get($shades, "black"), 0.12);
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

table {
  border-collapse: collapse;
}

td {
  position: relative;
  padding: 5px 10px;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: thin solid rgba(map-get($shades, "black"), 0.4);
  width: 100%;
}
</style>

<template>
  <v-container id="orders-to-print">
    <v-row class="mb-4" style="page-break-after: always" v-if="includeIndex">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Antal bokningar:</strong>
                </td>
                <td>{{ ordersToPrint.length }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Från:</strong>
                </td>
                <td>{{ from }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Till:</strong>
                </td>
                <td>{{ to }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left">Produkt</th>
                <th class="text-right">Pris</th>
                <th class="text-right">Enhet</th>
                <th class="text-right">Antal</th>
                <th class="text-right">-</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in totalItems" :key="item.title">
                <td width="30%" class="text-left">{{ item.title }}</td>
                <td class="text-right">{{ item.price }} kr/{{ item.unit }}</td>
                <td class="text-right">{{ item.unit }}</td>
                <td class="text-right">{{ item.quantity }}</td>
                <td class="text-right">
                  <v-icon>mdi-square-outline</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row
      v-for="order in ordersToPrint"
      :key="order.orderId"
      class="mb-4"
      style="page-break-after: always"
    >
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Ordernummer:</strong>
                </td>
                <td>{{ order.orderId }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Namn:</strong>
                </td>
                <td>{{ order.customer.name }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Epost:</strong>
                </td>
                <td>{{ order.customer.email }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Telefon:</strong>
                </td>
                <td>{{ order.customer.phone }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Bokningsdatum:</strong>
                </td>
                <td>{{ order.orderedAt | formatDate }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Leveransdatum:</strong>
                </td>
                <td>{{ order.deliveryDate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="30%" class="text-left">Produkt</th>
                <th class="text-right">Pris</th>
                <th class="text-right">Enhet</th>
                <th class="text-right">Antal</th>
                <th class="text-right">-</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.items" :key="item.id">
                <td width="30%" class="text-left">{{ item.title }}</td>
                <td class="text-right">{{ item.price || "-" }}</td>
                <td class="text-right">{{ item.unit }}</td>
                <td class="text-right">{{ item.quantity }}</td>
                <td class="text-right">
                  <v-icon>mdi-square-outline</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { groupBy } from "lodash";
import { isAfter, isBefore } from "date-fns";

export default {
  computed: {
    ...mapGetters(["confirmedOrders"]),
    from() {
      return this.$route.query.from;
    },
    to() {
      return this.$route.query.to;
    },
    includeIndex() {
      return this.$route.query.includeIndex;
    },
    ordersToPrint() {
      return this.confirmedOrders.filter(
        order =>
          isAfter(new Date(order.deliveryDate), new Date(this.from)) &&
          isBefore(new Date(order.deliveryDate), new Date(this.to))
      );
    },
    totalItems() {
      const itemsByName = groupBy(
        this.confirmedOrders.map(order => order.items).flat(),
        item => item.id
      );

      return Object.values(itemsByName).map(items =>
        items.reduce(
          (acc, item) => ({
            title: item.title,
            unit: item.unit,
            price: item.price,
            quantity: acc.quantity + item.quantity
          }),
          {
            name: "Unknown",
            quantity: 0
          }
        )
      );
    }
  },
  mounted() {
    this.$htmlToPaper("orders-to-print", null, () => this.$router.go(-1));
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        large
        v-bind="attrs"
        v-on="on"
        data-testid="openConfirmOrderDialogButton"
      >
        Bekräfta

        <v-icon right dark>
          mdi-check
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="px-4 py-4 text-center green darken-2">
        <h1 class="text-h4 white--text">Bekräfta Order {{ order.orderId }}</h1>
      </div>

      <v-container>
        <v-simple-table dense style="max-height: 400px; overflow-x: auto">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-left">
                  Vara
                </th>
                <th class="text-left">
                  Antal
                </th>
                <th class="text-left">
                  Enhet
                </th>
                <th class="text-left">
                  pris
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in order.items" :key="item.id">
                <td>
                  <v-checkbox
                    :true-value="item.id"
                    :false-value="false"
                    v-model="itemsToDeliver[index]"
                  />
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.price }} kr/{{ item.unit }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-row>
          <v-col
            class="text-center mt-4"
            data-testid="confirmationDialogWarningText"
          >
            Är du helt säker på att du vill
            <strong>BEKRÄFTA </strong>
            order
            {{ order.orderId }}
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-card-actions class="px-3 pb-3 pt-5">
        <v-btn color="error" text @click.prevent="onCancel" outlined>
          Stäng
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click.prevent="onConfirm"
          outlined
          :disabled="itemsToDeliver.some(Boolean) === false"
        >
          Bekräfta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { confirmOrder } from "../../firebase/orders";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      itemsToDeliver: []
    };
  },
  created() {
    this.order.items.forEach(item => {
      this.itemsToDeliver.push(item.id);
    });
  },
  methods: {
    async onConfirm() {
      if (this.itemsToDeliver.some(Boolean) === false) {
        return;
      }

      if (this.itemsToDeliver.some(item => item === false)) {
        const proposedItems = this.itemsToDeliver.filter(Boolean);

        await confirmOrder(
          this.order.orderId,
          proposedItems.map(id =>
            this.order.items.find(orderItem => orderItem.id === id)
          )
        );
      } else {
        await confirmOrder(this.order.orderId);
      }

      this.dialog = false;
    },
    onCancel() {
      this.dialog = false;
    }
  }
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";

// Views
import Giftcards from "../views/Giftcards.vue";
import Home from "../views/Home.vue";
import Inbox from "../views/Inbox.vue";
import Login from "../views/Login.vue";
import NoAccess from "../views/NoAccess.vue";
import Orders from "../views/Orders.vue";
import Cancelled from "../views/Cancelled.vue";
import Delivered from "../views/Delivered.vue";
import Print from "../views/Print.vue";
import AwaitingCustomer from "../views/AwaitingCustomer.vue";
import GDPR from "../views/GDPR.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/inbox",
    name: "inbox",
    component: Inbox
  },
  {
    path: "/giftcards",
    name: "giftcards",
    component: Giftcards
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders
  },
  {
    path: "/cancelled",
    name: "cancelled",
    component: Cancelled
  },
  {
    path: "/awaiting-customer",
    name: "awaitingCustomer",
    component: AwaitingCustomer
  },
  {
    path: "/delivered",
    name: "delivered",
    component: Delivered
  },
  {
    path: "/print",
    name: "print",
    component: Print
  },
  {
    path: "/gdpr",
    name: "gdpr",
    component: GDPR
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/no-access",
    name: "no-access",
    component: NoAccess,
    meta: {
      allowAnonymous: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const { authenticated } = store.getters;

  if (!to.meta?.allowAnonymous === true && !authenticated) {
    sessionStorage.setItem("nextUrlName", to.name);

    return next({
      path: "/login"
    });
  }

  return next();
});

export default router;

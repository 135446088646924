import store from "../store";
import firebase from "./firebase";

const db = firebase.firestore();
const giftcardsCollection = db.collection("giftcards");

let giftcardsListenerCleanup = null;

export const setupListeners = () => {
  if (giftcardsListenerCleanup) {
    giftcardsListenerCleanup();
  }

  giftcardsListenerCleanup = giftcardsCollection.onSnapshot(snapshot => {
    console.log("giftcards updated");

    const giftcards = [];

    snapshot.forEach(doc => giftcards.push(doc.data()));

    console.log(giftcards);
    store.commit({
      type: "setGiftcards",
      giftcards
    });
  });
};

export const cleanupListeners = () => {
  if (!giftcardsListenerCleanup) {
    return;
  }

  giftcardsListenerCleanup();

  giftcardsListenerCleanup = null;
};

export const saveGiftcard = giftcard => {
  console.log("Saving giftcard", { giftcard });

  const toSave = {
    createdAt: new Date(),
    ...giftcard
  };

  return firebase
    .firestore()
    .collection("giftcards")
    .doc(toSave.giftcardId)
    .set(toSave);
};

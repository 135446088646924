<template>
  <v-container class="py-8 px-6" fluid>
    <v-row class="mb-2">
      <v-col>
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          single-line
          v-model="filter"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card class="mx-auto" outlined>
          <v-subheader>Presentkort</v-subheader>

          <v-container>
            <v-list>
              <template v-for="(giftcard, index) in filteredGiftcards">
                <v-list-item
                  :key="giftcard.title"
                  :class="
                    selectedGiftcardId === giftcard.giftcardId &&
                      'grey lighten-3'
                  "
                  @click.prevent="onGiftcardClicked(giftcard)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text--primary">
                      Presentkort {{ giftcard.giftcardId }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider
                  v-if="index < filteredGiftcards.length - 1"
                  :key="index"
                />
              </template>
            </v-list>
          </v-container>

          <v-card-actions>
            <v-btn large outlined text @click.prevent="onNewClicked">
              Nytt presentkort
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6">
        <giftcard-form
          v-if="newGiftcard"
          v-on:cancel="handleNewCancel"
          v-on:saved="handleNewSaved"
        />
        <giftcard-display :giftcard="selectedGiftcard" v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import GiftcardForm from "../components/giftcards/GiftcardForm";
import GiftcardDisplay from "../components/giftcards/GiftcardDisplay";

export default {
  components: {
    GiftcardForm,
    GiftcardDisplay
  },
  data() {
    return {
      filter: "",
      newGiftcard: false,
      selectedGiftcardId: "123"
    };
  },
  computed: {
    ...mapGetters(["giftcards"]),
    filteredGiftcards() {
      return this.giftcards.filter(giftcard =>
        giftcard.giftcardId.includes(this.filter)
      );
    },
    selectedGiftcard() {
      return this.giftcards.find(
        giftcard => giftcard.giftcardId === this.selectedGiftcardId
      );
    }
  },
  methods: {
    onNewClicked() {
      this.newGiftcard = true;
      this.selectedGiftcardId = undefined;
    },
    handleNewCancel() {
      this.newGiftcard = false;
    },
    handleNewSaved({ giftcardId }) {
      this.newGiftcard = false;
      this.selectedGiftcardId = giftcardId;
    },
    onGiftcardClicked(giftcard) {
      this.newGiftcard = false;
      this.selectedGiftcardId = giftcard.giftcardId;
    }
  }
};
</script>

<template>
  <v-container class="py-8 px-6" fluid>
    <v-row class="mb-2">
      <v-col>
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          single-line
          v-model="filter"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <print-dialog icon />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="[key, orders] in orderGroups" :key="key" cols="12">
        <v-card flat outlined>
          <v-subheader>{{ key }}</v-subheader>

          <order-panels :orders="orders" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { groupBy, orderBy } from "lodash";
import OrderPanels from "../components/OrderPanels";
import PrintDialog from "../components/homeCards/PrintDialog.vue";

export default {
  components: {
    OrderPanels,
    PrintDialog
  },
  data() {
    return {
      filter: ""
    };
  },
  computed: {
    ...mapGetters(["confirmedOrders"]),
    orderGroups() {
      return Object.entries(
        groupBy(
          orderBy(
            this.confirmedOrders.filter(this.matchesFilter),
            order => new Date(order.deliveryDate)
          ),
          order => new Date(order.deliveryDate).toDateString()
        )
      );
    }
  },
  methods: {
    onPrintClicked(orderId) {
      this.$htmlToPaper(`order-${orderId}`);
    },
    matchesFilter(order) {
      if (!order) {
        return false;
      }

      const { orderId, customer } = order;

      if (orderId?.startsWith(this.filter)) {
        return true;
      }

      if (customer?.name?.toLowerCase().includes(this.filter.toLowerCase())) {
        return true;
      }

      if (customer?.email?.toLowerCase().includes(this.filter.toLowerCase())) {
        return true;
      }

      return false;
    }
  }
};
</script>

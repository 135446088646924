import firebase from "./firebase";
import store from "../store";
import router from "../router";

import {
  setupListeners as setupOrderListeners,
  cleanupListeners as cleanupOrderListeners
} from "./orders";
import {
  setupListeners as setupGiftcardListeners,
  cleanupListeners as cleanupGiftcardListeners
} from "./giftcards";

const validUsers = [
  "emil.bergstrom1@gmail.com",
  "hanna99.hoglund@gmail.com",
  "firrenablidingo@gmail.com"
];

const isValidUser = user => {
  if (/.*@firren[.]org$/.test(user.email)) {
    return true;
  }

  return validUsers.includes(user.email);
};

export const signOut = async () => {
  cleanupGiftcardListeners();
  cleanupOrderListeners();

  await firebase.auth().signOut();

  store.commit({
    type: "signedOut"
  });
};

firebase.auth().onAuthStateChanged(async user => {
  console.log(user);
  if (!user) {
    return signOut();
  }

  if (isValidUser(user) === false) {
    await signOut();
    return router.push({
      name: "no-access"
    });
  }

  setupGiftcardListeners();
  setupOrderListeners();

  store.commit({
    type: "setCurrentUser",
    user
  });

  const nextUrlName = sessionStorage.getItem("nextUrlName");
  sessionStorage.removeItem("nextUrlName");

  router.push({
    name: nextUrlName ?? "home"
  });
});

import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyAye3JDOOc0y73T_-ej7ujV9bH6-3EHxKY",
  authDomain: "firren-3fe33.firebaseapp.com",
  projectId: "firren-3fe33",
  storageBucket: "firren-3fe33.appspot.com",
  messagingSenderId: "716319697205",
  appId: "1:716319697205:web:f1a60b3d500d63888e447c",
  measurementId: "G-49SJLN73R5"
});

export default firebase;

<template>
  <v-row class="mt-2">
    <v-col align="right">
      <confirm-order-dialog :order="order" />
    </v-col>
  </v-row>
</template>

<script>
import ConfirmOrderDialog from "./ConfirmOrderDialog";

export default {
  components: { ConfirmOrderDialog },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
};
</script>
